import React from "react";
// import { app, database } from "./firebaseConfig";

import Home from "./Home";
import Navbar from "./components/Navbar";

export default function App() {
  return (
    <>
      <Navbar />
      <Home />
    </>
  );
}
